<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">试卷管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">组卷规则</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="tacticsRule_left" v-if="pageData.compositionType == '0'">
              <div class="shell">
                <div class="shell_1">基本信息</div>
                <div class="shell_4">预(抽题数)：{{ pageData.extractTotal }}</div>
                <div class="shell_3">
                  <el-button size="small" class="bgc-bv" icon="el-icon-plus" @click="addBigQuestion"
                    :disabled="tacticsPageData.ruleIdType == 'seeView'">添加大题</el-button>
                </div>
                <div class="shell_2" v-for="(item, index) in pageData.partitionList" :key="index"
                  @click="onTouch('tacticsRule_right_list' + index)">
                  第<span>{{ numberToUpperCase(index) }}</span>大题
                </div>
              </div>
            </div>
            <div class="tacticsRule_right">
              <el-form ref="courseTypeDialogFromRef" size="small" :model="pageData" :rules="pageDataRules"
                :disabled="tacticsPageData.ruleIdType == 'seeView'" label-width="120px">
                <el-form-item class="jbxx" label="基本信息"></el-form-item>
                <el-form-item label="试卷名称" prop="paperName">
                  <el-input v-model="pageData.paperName" :disabled="pageData.firstNameDisabled" placeholder="请输入试卷名称"
                    maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="试卷类型" prop="examType">
                  <el-radio-group v-model="pageData.examType">
                    <el-radio v-for="(item, index) in ExaminationTypeData" :key="index" :label="item.value">{{ item.label
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="组卷形式" prop="compositionType">
                  <el-radio-group v-model="pageData.compositionType"
                    :disabled="tacticsPageData.ruleIdType == 'copyUpdate'">
                    <el-radio v-for="(item, index) in CompositionTypeData" :key="index" :label="item.value">{{ item.label
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="试卷数量" prop="paperNum">
                  <el-input v-model="pageData.paperNum"
                    :disabled="pageData.compositionType == '1' || tacticsPageData.ruleIdType == 'copyUpdate'"
                    @keyup.native="pageData.paperNum = zF.positiveInteger(pageData.paperNum)"
                    style="width: 100px;"></el-input> 套
                </el-form-item>
                <el-form-item label="试题来源" prop="bankId">
                  <el-select clearable v-model="pageData.bankId" filterable remote :remote-method="searchCopmp"
                    placeholder="请至少输入两个字搜索" :disabled="tacticsPageData.ruleIdType == 'copyUpdate'"
                    @change="rebuildKnowledge(pageData.bankId)">
                    <el-option size="small" v-for="item in compList" :key="item.bankId" :label="item.bankName"
                      :value="item.bankId"></el-option>
                  </el-select>
                </el-form-item>
                <!-- 组卷形式：题库抽题才显示 -->
                <template v-if="pageData.bankId && pageData.compositionType == '0'">
                  <el-form-item label="抽题规则" prop="extractQuestionType">
                    <el-radio-group v-model="pageData.extractQuestionType" @change="changeExtractQuestionType">
                      <el-radio :label="'10'">自定义</el-radio>
                      <el-radio :label="'20'">权重</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="按知识点抽题" prop="isKnowledge" v-if="pageData.extractQuestionType == '10'">
                    <el-radio-group v-model="pageData.isKnowledge">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item prop="isUserShow">
                    <el-checkbox v-model="pageData.isUserShow">将按规则抽出的试卷题目设置为学员可练习, 题库其他题目学员练习不可见</el-checkbox>
                  </el-form-item>
                </template>
                <el-form-item>
                  <span
                    style="font-size: 12px;color: #9e9e9e;line-height: 20px;">注：组合题（复合题型）按照大题数量抽取试题，组合题（单一题型）按照小题数量抽取试题。</span>
                </el-form-item>
              </el-form>
              <div :id="'tacticsRule_right_list' + index" class="tacticsRule_right_list"
                v-for="(item, index) in pageData.partitionList" :key="index">
                <div class="listTop">
                  <div>
                    <span class="listTop_left_1">{{ item.partitionName }}</span>
                    <el-button type="text" class="listTop_left_2" icon="el-icon-edit"
                      @click="reviseBigQuestionTitle(item, index)"
                      :disabled="tacticsPageData.ruleIdType == 'seeView'"></el-button>
                    （共计 <span class="listTop_left_3">{{ item.pctzs }}</span> 道试题，计 <span class="listTop_left_3">{{
                      item.pctzfs }}</span> 分）
                  </div>
                  <div>
                    <el-button class="bgc-bv" size="mini" @click="setShift('up', index, item)" v-if="index > 0"
                      :disabled="tacticsPageData.ruleIdType == 'seeView'">上移</el-button>
                    <el-button class="bgc-bv" size="mini" @click="setShift('down', index, item)"
                      v-if="!(index + 1 >= pageData.partitionList.length)"
                      :disabled="tacticsPageData.ruleIdType == 'seeView'">下移</el-button>
                    <el-button class="bgc-bv" size="mini" @click="deleteBigQuestion(index)"
                      :disabled="tacticsPageData.ruleIdType == 'seeView'">删除大题</el-button>
                  </div>
                </div>
                <!-- 抽题规则：自定义 -->
                <el-table ref="multipleTable" :data="item.clauseList" size="small" tooltip-effect="dark"
                  style="width: 100%;" :header-cell-style="tableHeader" stripe v-if="pageData.extractQuestionType == '10'">
                  <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
                  <el-table-column label="试题类型" align="center" show-overflow-tooltip prop="questionType" width="200px">
                    <template slot-scope="scope">
                      <el-cascader clearable filterable v-model="scope.row.questionType" :options="questionTypeData"
                        :props="questionTypeProps" size="small" placeholder="请选择"
                        @change="selectQuestionType($event, scope.row)"
                        :disabled="tacticsPageData.ruleIdType == 'seeView'"></el-cascader>
                    </template>
                  </el-table-column>
                  <el-table-column label="知识点" align="center" show-overflow-tooltip prop="knowledgeId" min-width="200px">
                    <template slot-scope="scope">
                      <el-cascader clearable filterable v-model="scope.row.knowledgeId" :options="areatreeList"
                        :props="propsarea" size="small" placeholder="请选择" @change="selectKnowledgeId($event, scope.row)"
                        :disabled="tacticsPageData.ruleIdType == 'seeView'"></el-cascader>
                    </template>
                  </el-table-column>
                  <el-table-column label="抽题数量" align="center" show-overflow-tooltip width="300px">
                    <el-table-column label="学员可见" align="center" show-overflow-tooltip prop="showQuestionNum"
                      width="150px">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.showQuestionNum"
                          @keyup.native="scope.row.showQuestionNum = zF.positiveInteger(scope.row.showQuestionNum)"
                          @blur="showStudent(scope.row, index)" size="small"
                          :disabled="tacticsPageData.ruleIdType == 'seeView'"
                          style="width:60px;"></el-input>
                        <span :key="tableKeyWidth"> / {{ scope.row.showNum }} {{
                          scope.row.questionType == '80' ? '小题' : '题' }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="学员不可见" align="center" show-overflow-tooltip prop="hideQuestionNum"
                      width="150px">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.hideQuestionNum"
                          @keyup.native="scope.row.hideQuestionNum = zF.positiveInteger(scope.row.hideQuestionNum)"
                          @blur="hiddenStudent(scope.row, index)" size="small"
                          :disabled="tacticsPageData.ruleIdType == 'seeView'"
                          style="width:60px;"></el-input>
                        <span :key="tableKeyWidth">/ {{ scope.row.hideNum }} {{
                          scope.row.questionType == '80' ? '小题' : '题' }}</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="试题总数" align="center" show-overflow-tooltip prop="stzs"
                    width="100px"></el-table-column>
                  <el-table-column label="分数设置(分)" align="center" show-overflow-tooltip prop="score" width="150px">
                    <template slot-scope="scope">
                      {{ scope.row.questionType == '70' || scope.row.questionType == '80' ? '每小题' : '每题' }}<el-input
                        v-model="scope.row.score"
                        @keyup.native="scope.row.score = zF.oninput2(scope.row.score, 2)" @blur="setFs(scope.row, index)"
                        size="small" :disabled="tacticsPageData.ruleIdType == 'seeView'" style="width:60px;" :key="tableKeyWidth"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="总分数" align="center" show-overflow-tooltip prop="zfs" width="80px">
                    <template slot-scope="scope">
                      <span :key="tableKeyWidth">{{ scope.row.questionType == '70' ? '--' : scope.row.zfs }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" align="center" width="80px" fixed="right">
                    <div slot-scope="scope" class="flexcc">
                      <el-button type="text" style="padding:0px 5px" size="mini"
                        @click="deleteListData(scope.row, scope.$index, item.clauseList, index)"
                        :disabled="tacticsPageData.ruleIdType == 'seeView'">移除</el-button>
                    </div>
                  </el-table-column>
                </el-table>
                <!-- 抽题规则：权重 -->
                <el-table ref="multipleTable" :data="item.clauseList" size="small" tooltip-effect="dark"
                  style="width: 100%;" :header-cell-style="tableHeader" stripe v-if="pageData.extractQuestionType == '20'">
                  <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
                  <el-table-column label="试题类型" align="center" show-overflow-tooltip prop="questionType"
                    min-width="200px">
                    <template slot-scope="scope">
                      <el-cascader clearable filterable v-model="scope.row.questionType" :options="questionTypeData"
                        :props="questionTypeProps" size="small" placeholder="请选择"
                        @change="selectQuestionType20($event, scope.row)"
                        :disabled="tacticsPageData.ruleIdType == 'seeView'"></el-cascader>
                    </template>
                  </el-table-column>
                  <el-table-column label="试题总数" align="center" show-overflow-tooltip prop="stzs"
                    width="100px">
                    <template slot-scope="scope">
                      <span :key="tableKeyWidth">{{ scope.row.stzs }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="抽题数量" align="center" show-overflow-tooltip width="300px">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.showQuestionNum"
                        @keyup.native="scope.row.showQuestionNum = zF.positiveInteger(scope.row.showQuestionNum)"
                        @blur="showStudent20(scope.row, index)" size="small"
                        :disabled="tacticsPageData.ruleIdType == 'seeView'"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="每题分数" align="center" show-overflow-tooltip prop="score" min-width="150px">
                    <template slot-scope="scope">
                      <!-- {{ scope.row.questionType == '70' || scope.row.questionType == '80' ? '每小题' : '每题' }} -->
                      <el-input v-model="scope.row.score"
                        @keyup.native="scope.row.score = zF.oninput2(scope.row.score, 2)" @blur="setFs20(scope.row, index)"
                        size="small" :disabled="tacticsPageData.ruleIdType == 'seeView'"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="总分数" align="center" show-overflow-tooltip prop="zfs" min-width="80px">
                    <template slot-scope="scope">
                      <span :key="tableKeyWidth">{{ scope.row.questionType == '70' ? '--' : scope.row.zfs }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" align="center" width="80px" fixed="right">
                    <div slot-scope="scope" class="flexcc">
                      <el-button type="text" style="padding:0px 5px" size="mini"
                        @click="deleteListData20(scope.row, scope.$index, item.clauseList, index)"
                        :disabled="tacticsPageData.ruleIdType == 'seeView'">移除</el-button>
                    </div>
                  </el-table-column>
                </el-table>
                <div class="listBottom">
                  <el-button size="small" class="bgc-bv" @click="addExtractionStrategy(item)"
                    :disabled="tacticsPageData.ruleIdType == 'seeView'">添加抽题策略</el-button>
                </div>
              </div>
            </div>
            <div class="operateDiv">
              <el-button size="small" @click="noPreserve">取消</el-button>
              <el-button size="small" class="bgc-bv" @click="okPreserve"
                :disabled="tacticsPageData.ruleIdType == 'seeView'" v-if="pageData.compositionType == '0'">
                保存
              </el-button>
              <el-button size="small" class="bgc-bv" @click="okNext" :disabled="tacticsPageData.ruleIdType == 'seeView'"
                v-if="pageData.compositionType == '1'">
                下一步
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 大题标题 - 弹框 -->
    <el-dialog title="编辑大题标题" :visible.sync="reviseBigQuestionTitleData.dialogVisible" width="30%" center>
      <el-form ref="courseTypeDialogFromRef" :model="reviseBigQuestionTitleData" label-width="120px">
        <el-form-item label="大题标题">
          <el-input size="small" v-model="reviseBigQuestionTitleData.partitionName" maxlength="30" show-word-limit
            placeholder="请输入大题标题"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reviseBigQuestionTitleData.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="okReviseBigQuestionTitle()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: "inkQuestionBank_examinationPaperManage_tacticsRule",
  components: {
  },
  data() {
    return {
      // 规则列表宽度判断
      tableWidth: true,
      // 表格表头样式
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#7aafff",
      },
      // 试卷管理页面传过来的参数
      tacticsPageData: {
        ruleId: "", // 试卷id
        ruleIdType: "", // 进来的类型：seeView-预览；copyView-复制；copyUpdate-自行组卷
      },
      // 题库数据
      compList: [],
      // 试卷类型数据
      ExaminationTypeData: [],
      // 组卷形式
      CompositionTypeData: [],
      // 试题类型数据
      questionTypeData: [],
      // 知识点数据
      areatreeList: [],
      // 知识点 - 数据格式
      propsarea: {
        value: "knowledgeId",
        label: "knowledgeName",
        emitPath: false,
        checkStrictly: true,
      },
      // 试题类型 - 数据格式
      questionTypeProps: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
      // 页面数据
      pageData: {
        extractTotal: 0, // 预(抽题数)
        paperName: "", // 试卷名称
        examType: "10", // 试卷类型
        compositionType: "0", // 组卷形式
        paperNum: "1", // 试卷数量
        bankId: "", // 试题来源-题库id
        extractQuestionType: "10", // 抽题规则
        isKnowledge: true, // 按知识点抽题
        isUserShow:false,
        // 题目列表
        partitionList: [
          // {
          //   // 大题标题
          //   partitionName: "",
          //   // 抽题总数
          //   pctzs:"",
          //   // 抽题总分数
          //   pctzfs:"",
          //   // 抽题规则列数据
          //   clauseList: [{
          //     questionType: "", // 试题类型
          //     knowledgeId: "", // 知识点
          //     showQuestionNum: "", // 可见学员
          //     hideQuestionNum: "", // 不可见学员
          //     stzs: "", // 试题总分
          //     score: "", // 分数设置
          //     zfs: "", // 总分数
          //   }]
          // },
        ],
      },
      // 页面数据校验
      pageDataRules: {
        paperName: [
          {
            required: true,
            message: "请输入试卷名称",
            trigger: "blur",
          },
        ],
        examType: [
          {
            required: true,
            message: "请选择试卷类型",
            trigger: "change",
          },
        ],
        compositionType: [
          {
            required: true,
            message: "请选择组卷形式",
            trigger: "change",
          },
        ],
        bankId: [
          {
            required: true,
            message: "请选择题库",
            trigger: "change",
          },
        ],
        paperNum: [
          {
            required: true,
            message: "请输入试卷数量",
            trigger: "blur",
          },
        ],
        extractQuestionType: [
          {
            required: true,
            message: "请选择抽题规则",
            trigger: "change",
          },
        ],
        isKnowledge: [
          {
            required: true,
            message: "请选择是否按知识点抽题",
            trigger: "change",
          },
        ],
      },
      // 修改大题标题弹框
      reviseBigQuestionTitleData: {
        dialogVisible: false, // 弹框状态
        partitionName: "", // 大题标题
        classifyIndex: "", // 大题的下标
      },
      // 列表加key防止数据显示不出来
      tableKeyWidth: new Date(),
    };
  },
  mixins: [],
  created() {
    this.tacticsPageData.ruleId = this.$route.query.ruleId || "";
    this.tacticsPageData.ruleIdType = this.$route.query.ruleIdType || "";
    this.getInvoice_type();
    if (this.$route.query.ruleIdType) {
      this.getRuleInfo();
    }
  },
  computed: {

  },
  methods: {
    // 搜索题库
    searchCopmp(query) {
      if (query.trim().length >= 1) {
        this.$post("/biz/exam/bank/pageList", { bankName: query }, 3000, true, 6).then(
          (res) => {
            this.compList = res.data.list;
            // 清空所用纸卷规则列表数据
            this.pageData.partitionList = [];
            // 重置抽题规则
            this.pageData.extractQuestionType = '10';
          }
        );
      } else {
        this.compList = [];
      }
    },
    // 搜索题库 - 用id查，回显得时候用这个
    searchCopmpId(bankId) {
      this.$post("/biz/exam/bank/examBankList", { bankId }, 3000, true, 6).then(
        (res) => {
          this.compList = res.data;
        }
      );
    },
    // 获取字典
    getInvoice_type() {
      // 试题类型：10 - 单选题; 20-多选题; 30 - 判断题; 40 - 填空题; 50 - 简答题; 60 - 计算题; 70 - 组合题(复合题型); 80 - 组合题(单一题型)
      let arr1 = this.$setDictionary("TOOL_QUESTION_TYPE", "list");
      let questionTypeDataChildren = [];
      for (const key in arr1) {
        if (key != '80' && key != '70') {
          questionTypeDataChildren.push({
            label: arr1[key],
            value: '80,' + key
          });
        }
        if (key == '80') {
          this.questionTypeData.push({
            label: arr1[key],
            value: key,
            children: questionTypeDataChildren
          });
        } else {
          this.questionTypeData.push({
            label: arr1[key],
            value: key
          });
        }
      }
      // 试卷类型：10 - 模拟试卷; 20 - 练习试卷; 30 - 考试试卷
      let arr2 = this.$setDictionary("TOOL_EXAM_TYPE", "list");
      for (const key in arr2) {
        this.ExaminationTypeData.push({
          label: arr2[key],
          value: key
        });
      }
      // 组卷形式
      let arr3 = this.$setDictionary("TOOL_COMPOSITION_TYPE", "list");
      for (const key in arr3) {
        this.CompositionTypeData.push({
          label: arr3[key],
          value: key
        });
      }
    },
    // 重新获取知识点
    rebuildKnowledge(bankId) {
      this.$post("/biz/exam/bank/knowledge/selectable/tree", { "bankId": bankId }, 3000, true, 6).then((res) => {
        // 递归处理为空的 children
        function recurrenceChildren(list) {
          for (let i = 0; i < list.length; i++) {
            if (list[i].children && list[i].children < 1) {
              delete list[i].children;
            } else {
              recurrenceChildren(list[i].children);
            }
          }
        }
        recurrenceChildren(res.data);
        this.areatreeList = res.data;
      });
    },
    // 选择抽题规则
    changeExtractQuestionType(val) {
      this.$post("/biz/exam/bank/extract/rule/isSettingWeight", {
        bankId: this.pageData.bankId, // 题库id
      }, 3000, true, 6).then((res) => {
        // 该题库是否配置了权重
        if (!res.data) {
          this.pageData.extractQuestionType = '10';
          this.$message({
            type: "warning",
            message: "该题库未设置权重，请前往【题库管理】-【知识点管理】模块设置权重！",
          });
        } else {
          // 重置题目列表
          this.pageData.partitionList = [];
        }
      });
    },
    // 获取试卷规格详情
    getRuleInfo() {
      this.$post("/biz/exam/bank/extract/rule/getSetting", { ruleId: this.$route.query.ruleId }, 3000, true, 6).then(res => {
        this.pageData = { ...this.pageData, ...res.data };
        console.log(this.pageData)
        this.searchCopmpId(res.data.bankId);
        this.rebuildKnowledge(res.data.bankId);
        for (let i = 0; i < this.pageData.partitionList.length; i++) {
          for (let n = 0; n < this.pageData.partitionList[i].clauseList.length; n++) {
            // 抽题规则：自定义 10
            if (this.pageData.extractQuestionType == '10') {
              // 该行试题总数 = 可见学员题 + 不可见学员题
              this.pageData.partitionList[i].clauseList[n].stzs = Number(this.pageData.partitionList[i].clauseList[n].showQuestionNum) + Number(this.pageData.partitionList[i].clauseList[n].hideQuestionNum);
              // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
              if (this.pageData.partitionList[i].clauseList[n].questionType == '70') {
                this.pageData.partitionList[i].clauseList[n].zfs = 0
              } else {
                // 总分数 = 试题总数 * 分数设置
                this.pageData.partitionList[i].clauseList[n].zfs = (Number(this.pageData.partitionList[i].clauseList[n].stzs) * Number(this.pageData.partitionList[i].clauseList[n].score)).toFixed(2);
              }
              // 抽题总数
              this.pageData.partitionList[i].pctzs = Number(this.pageData.partitionList[i].pctzs || 0) + Number(this.pageData.partitionList[i].clauseList[n].stzs);
              // 抽题总分数
              this.pageData.partitionList[i].pctzfs = (Number(this.pageData.partitionList[i].pctzfs || 0) + Number(this.pageData.partitionList[i].clauseList[n].zfs)).toFixed(2);
            }
            // 抽题规则：权重 20
            if (this.pageData.extractQuestionType == '20') {
              // 该行试题总数 = 可见学员题 + 不可见学员题
              this.pageData.partitionList[i].clauseList[n].stzs = Number(this.pageData.partitionList[i].clauseList[n].showNum) + Number(this.pageData.partitionList[i].clauseList[n].hideNum);
              // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
              if (this.pageData.partitionList[i].clauseList[n].questionType == '70') {
                this.pageData.partitionList[i].clauseList[n].zfs = 0
              } else {
                // 总分数 = 试题总数 * 分数设置
                this.pageData.partitionList[i].clauseList[n].zfs = (Number(this.pageData.partitionList[i].clauseList[n].showQuestionNum) * Number(this.pageData.partitionList[i].clauseList[n].score)).toFixed(2);
              }
              // 抽题总数
              this.pageData.partitionList[i].pctzs = Number(this.pageData.partitionList[i].pctzs || 0) + Number(this.pageData.partitionList[i].clauseList[n].stzs);
              // 抽题总分数
              this.pageData.partitionList[i].pctzfs = (Number(this.pageData.partitionList[i].pctzfs || 0) + Number(this.pageData.partitionList[i].clauseList[n].zfs)).toFixed(2);
            }
          }
        }
        this.setExtractTotal();
      });
    },
    // 添加大题
    addBigQuestion() {
      this.$refs['courseTypeDialogFromRef'].validate((valid) => {
        if (valid) {
          this.pageData.partitionList.push({
            // 大题标题
            partitionName: "",
            // 抽题总数
            pctzs: "0",
            // 抽题总分数
            pctzfs: "0",
            // 抽题规则列数据
            clauseList: [],
          })
        }
      });
    },
    // 更改大题标题
    reviseBigQuestionTitle(item, index) {
      this.reviseBigQuestionTitleData = this.$options.data().reviseBigQuestionTitleData;
      this.reviseBigQuestionTitleData.dialogVisible = true;
      this.reviseBigQuestionTitleData.partitionName = item.partitionName;
      this.reviseBigQuestionTitleData.classifyIndex = index;
    },
    // 更改大题标题 - 确定
    okReviseBigQuestionTitle() {
      this.pageData.partitionList[this.reviseBigQuestionTitleData.classifyIndex].partitionName = this.reviseBigQuestionTitleData.partitionName;
      this.reviseBigQuestionTitleData.dialogVisible = false;
    },
    // 更改大题标题 - 取消
    noReviseBigQuestionTitle() {
      this.reviseBigQuestionTitleData.dialogVisible = false;
    },
    // 上移&下移
    setShift(type, index) {
      if (type == "up") {
        let obj = this.pageData.partitionList[index - 1];
        this.$set(this.pageData.partitionList, index - 1, this.pageData.partitionList[index]);
        this.$set(this.pageData.partitionList, index, obj);
      } else {
        let obj = this.pageData.partitionList[index + 1];
        this.$set(this.pageData.partitionList, index + 1, this.pageData.partitionList[index]);
        this.$set(this.pageData.partitionList, index, obj);
      }
    },
    // 删除 - 大题
    deleteBigQuestion(index) {
      this.pageData.partitionList.splice(index, 1);
    },
    // 添加抽题策略
    addExtractionStrategy(item) {
      // 抽题规则：自定义
      if (this.pageData.extractQuestionType == '10') {
        item.clauseList.push({
          questionType: "", // 试题类型
          knowledgeId: "", // 知识点
          showQuestionNum: "", // 可见学员
          hideQuestionNum: "", // 不可见学员
          stzs: "", // 试题总数
          score: "", // 分数设置
          zfs: "", // 总分数
        })
      }
      // 抽题规则：权重     
      if (this.pageData.extractQuestionType == '20') {
        item.clauseList.push({
          questionType: "", // 试题类型
          stzs: "", // 试题总数
          showQuestionNum: "", // 抽题数量
          score: "", // 分数设置
          zfs: "", // 总分数
        })
      }
    },
    // (抽题规则：自定义 10) => 选择试题类型 - 查询可用题目数量{是否可见：可用数量} 并 清空列表赋值
    selectQuestionType(event, row) {
      row.showQuestionNum = '';
      row.hideQuestionNum = '';
      row.stzs = '';
      row.score = '';
      row.zfs = '';
      // 当前列: (是按知识点抽题 & 试题类型 & 知识点) || (否按知识点抽题 & 试题类型) => 同时选择才做计算处理
      if ((this.pageData.isKnowledge && row.questionType && row.knowledgeId) || (!this.pageData.isKnowledge && row.questionType)) {
        this.$post("/biz/exam/bank/extract/rule/selectQuestionNum", {
          bankId: this.pageData.bankId, // 题库id
          questionType: row.questionType, // 试题类型
          knowledgeId: row.knowledgeId, // 知识点
        }, 3000, true, 6).then((res) => {
          row.showNum = res.data.showNum;
          row.hideNum = res.data.hideNum;
          this.$forceUpdate();
          this.tableKeyWidth = new Date();
        });
      }
    },
    // (抽题规则：自定义 10) => 选择知识点 - 查询可用题目数量{是否可见：可用数量} 并 清空列表赋值
    selectKnowledgeId(event, row) {
      row.showQuestionNum = '';
      row.hideQuestionNum = '';
      row.stzs = '';
      row.score = '';
      row.zfs = '';
      // 当前列: (是按知识点抽题 & 试题类型 & 知识点) || (否按知识点抽题 & 试题类型) => 同时选择才做计算处理
      if ((this.pageData.isKnowledge && row.questionType && row.knowledgeId) || (!this.pageData.isKnowledge && row.questionType)) {
        this.$post("/biz/exam/bank/extract/rule/selectQuestionNum", {
          bankId: this.pageData.bankId, // 题库id
          questionType: row.questionType, // 试题类型
          knowledgeId: row.knowledgeId, // 知识点
        }, 3000, true, 6).then((res) => {
          row.showNum = res.data.showNum;
          row.hideNum = res.data.hideNum;
          this.$forceUpdate();
          this.tableKeyWidth = new Date();
        });
      }
    },
    // (抽题规则：自定义 10) => 学员可见 - 失去焦点出发算法
    showStudent(row, index) {
      // 当前列: (是按知识点抽题 & 试题类型 & 知识点) || (否按知识点抽题 & 试题类型) => 同时选择才做计算处理
      if ((this.pageData.isKnowledge && row.questionType && row.knowledgeId) || (!this.pageData.isKnowledge && row.questionType)) {
        // 如果：学员可见提数 >= 可见学员抽题总数，就取最大值
        if (Number(row.showQuestionNum) >= Number(row.showNum)) {
          row.showQuestionNum = Number(row.showNum);
        }
        // 试题总数 = 可见学员题 + 不可见学员题
        row.stzs = Number(row.showQuestionNum) + Number(row.hideQuestionNum);
        // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
        if (row.questionType == '70') {
          row.zfs = 0
        } else {
          // 总分数 = 试题总数 * 分数设置
          row.zfs = (Number(row.stzs) * Number(row.score)).toFixed(2);
        }
        // 循环累加计算：抽题总数 和 抽题总分数; 但计算时先归零
        this.pageData.partitionList[index].pctzs = 0;
        this.pageData.partitionList[index].pctzfs = 0;
        for (let i = 0; i < this.pageData.partitionList[index].clauseList.length; i++) {
          // 抽题总数
          this.pageData.partitionList[index].pctzs = Number(this.pageData.partitionList[index].pctzs) + Number(this.pageData.partitionList[index].clauseList[i].stzs);
          // 抽题总分数
          this.pageData.partitionList[index].pctzfs = (Number(this.pageData.partitionList[index].pctzfs) + Number(this.pageData.partitionList[index].clauseList[i].zfs)).toFixed(2);
        }
        this.setExtractTotal();
        this.tableKeyWidth = new Date();
      } else {
        // 清空学员可见题数
        row.showQuestionNum = '';
        this.$message({
          type: "warning",
          message: this.pageData.isKnowledge ? "请先选择：试题类型 和 知识点" : "请先选择：试题类型"
        });
      }
    },
    // (抽题规则：自定义 10) => 学员不可见 - 失去焦点出发算法
    hiddenStudent(row, index) {
      // 当前列: (是按知识点抽题 & 试题类型 & 知识点) || (否按知识点抽题 & 试题类型) => 同时选择才做计算处理
      if ((this.pageData.isKnowledge && row.questionType && row.knowledgeId) || (!this.pageData.isKnowledge && row.questionType)) {
        // 如果：学员可见提数 >= 可见学员抽题总数，就取最大值
        if (Number(row.hideQuestionNum) >= Number(row.hideNum)) {
          row.hideQuestionNum = Number(row.hideNum);
        }
        // 试题总数 = 可见学员题 + 不可见学员题
        row.stzs = Number(row.showQuestionNum) + Number(row.hideQuestionNum);
        // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
        if (row.questionType == '70') {
          row.zfs = 0
        } else {
          // 总分数 = 试题总数 * 分数设置
          row.zfs = (Number(row.stzs) * Number(row.score)).toFixed(2);
        }
        // 循环累加计算：抽题总数 和 抽题总分数; 但计算时先归零
        this.pageData.partitionList[index].pctzs = 0;
        this.pageData.partitionList[index].pctzfs = 0;
        for (let i = 0; i < this.pageData.partitionList[index].clauseList.length; i++) {
          // 抽题总数
          this.pageData.partitionList[index].pctzs = Number(this.pageData.partitionList[index].pctzs) + Number(this.pageData.partitionList[index].clauseList[i].stzs);
          // 抽题总分数
          this.pageData.partitionList[index].pctzfs = (Number(this.pageData.partitionList[index].pctzfs) + Number(this.pageData.partitionList[index].clauseList[i].zfs)).toFixed(2);
        }
        this.setExtractTotal();
        this.tableKeyWidth = new Date();
      } else {
        // 清空学员可见题数
        row.hideQuestionNum = '';
        this.$message({
          type: "warning",
          message: this.pageData.isKnowledge ? "请先选择：试题类型 和 知识点" : "请先选择：试题类型"
        });
      }
    },
    // (抽题规则：自定义 10) => 分数设置 - 失去焦点出发算法
    setFs(row, index) {
      // 当前列: (是按知识点抽题 & 试题类型 & 知识点) || (否按知识点抽题 & 试题类型) => 同时选择才做计算处理
      if ((this.pageData.isKnowledge && row.questionType && row.knowledgeId) || (!this.pageData.isKnowledge && row.questionType)) {
        // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
        if (row.questionType == '70') {
          row.zfs = 0
        } else {
          // 总分数 = 试题总数 * 分数设置
          row.zfs = (Number(row.stzs) * Number(row.score)).toFixed(2);
        }
        // 循环累加计算：抽题总数 和 抽题总分数; 但计算时先归零
        this.pageData.partitionList[index].pctzs = 0;
        this.pageData.partitionList[index].pctzfs = 0;
        for (let i = 0; i < this.pageData.partitionList[index].clauseList.length; i++) {
          // 抽题总数
          this.pageData.partitionList[index].pctzs = Number(this.pageData.partitionList[index].pctzs) + Number(this.pageData.partitionList[index].clauseList[i].stzs);
          // 抽题总分数
          this.pageData.partitionList[index].pctzfs = (Number(this.pageData.partitionList[index].pctzfs) + Number(this.pageData.partitionList[index].clauseList[i].zfs)).toFixed(2);
        }
        this.tableKeyWidth = new Date();
      } else {
        // 清空学员可见题数
        row.score = '';
        this.$message({
          type: "warning",
          message: this.pageData.isKnowledge ? "请先选择：试题类型 和 知识点" : "请先选择：试题类型"
        });
      }
    },
    // (抽题规则：自定义 10) => 删除 - 列表单行数据(当前列数据，当前列下标，大题当前数据，大题当前下标)
    deleteListData(row, rowIndex, clauseList, clauseListIndex) {
      this.pageData.partitionList[clauseListIndex].pctzs = Number(this.pageData.partitionList[clauseListIndex].pctzs) - Number(row.showQuestionNum) - Number(row.hideQuestionNum);
      this.pageData.partitionList[clauseListIndex].pctzfs = (Number(this.pageData.partitionList[clauseListIndex].pctzfs) - Number(row.zfs)).toFixed(2);
      clauseList.splice(rowIndex, 1);
      this.setExtractTotal();
    },
    // (抽题规则：自定义 20) => 选择试题类型 - 查询可用题目数量 并 清空列表赋值
    selectQuestionType20(event, row) {
      row.stzs = ''; // 试题总数
      row.showQuestionNum = ''; // 抽题数量
      row.score = ''; // 每题分数
      row.zfs = ''; // 总分数
      if(!row.questionType) return
      this.$post("/biz/exam/bank/extract/rule/selectQuestionNum", {
        bankId: this.pageData.bankId, // 题库id
        questionType: row.questionType // 试题类型
      }, 3000, true, 6).then((res) => {
        console.log(res)
        // 试题总数 = 学员可见题数 + 学员不可见题数
        row.stzs = res.data.showNum + res.data.hideNum;
        this.$forceUpdate();
        this.tableKeyWidth = new Date();
      });
    },
    // (抽题规则：自定义 20) => 抽题数量 - 失去焦点出发算法
    showStudent20(row, index) {
      // 试题类型必选
      if (row.questionType) {
        // 如果：抽题数>=试题总数 - 就取试题总数为最大值
        if (Number(row.showQuestionNum) >= Number(row.stzs)) {
          row.showQuestionNum = Number(row.stzs);
        }
        // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
        if (row.questionType == '70') {
          row.zfs = 0
        } else {
          // 总分数 = 试题总数 * 分数设置
          row.zfs = (Number(row.showQuestionNum) * Number(row.score)).toFixed(2);
        }
        // 循环累加计算：抽题总数 和 抽题总分数; 但计算时先归零
        this.pageData.partitionList[index].pctzs = 0;
        this.pageData.partitionList[index].pctzfs = 0;
        for (let i = 0; i < this.pageData.partitionList[index].clauseList.length; i++) {
          // 抽题总数
          this.pageData.partitionList[index].pctzs = Number(this.pageData.partitionList[index].pctzs) + Number(this.pageData.partitionList[index].clauseList[i].showQuestionNum);
          // 抽题总分数
          this.pageData.partitionList[index].pctzfs = (Number(this.pageData.partitionList[index].pctzfs) + Number(this.pageData.partitionList[index].clauseList[i].zfs)).toFixed(2);
        }
        this.setExtractTotal();
        this.tableKeyWidth = new Date();
      } else {
        // 清空学员可见题数
        row.showQuestionNum = '';
        this.$message({
          type: "warning",
          message: "请先选择：试题类型"
        });
      }
    },
    // (抽题规则：自定义 20) => 分数设置 - 失去焦点出发算法
    setFs20(row, index) {
      // 试题类型必选
      if (row.questionType) {
        // 总分数 = 0; 70组合题(复合题型): -该大题总分数是0
        if (row.questionType == '70') {
          row.zfs = 0
        } else {
          // 总分数 = 试题总数 * 分数设置
          row.zfs = (Number(row.showQuestionNum) * Number(row.score)).toFixed(2);
        }
        // 循环累加计算：抽题总数 和 抽题总分数; 但计算时先归零
        this.pageData.partitionList[index].pctzs = 0;
        this.pageData.partitionList[index].pctzfs = 0;
        for (let i = 0; i < this.pageData.partitionList[index].clauseList.length; i++) {
          // 抽题总数
          this.pageData.partitionList[index].pctzs = Number(this.pageData.partitionList[index].pctzs) + Number(this.pageData.partitionList[index].clauseList[i].showQuestionNum);
          // 抽题总分数
          this.pageData.partitionList[index].pctzfs = (Number(this.pageData.partitionList[index].pctzfs) + Number(this.pageData.partitionList[index].clauseList[i].zfs)).toFixed(2);
        }
        this.tableKeyWidth = new Date();
      } else {
        // 清空学员可见题数
        row.showQuestionNum = '';
        this.$message({
          type: "warning",
          message: "请先选择：试题类型"
        });
      }
    },
    // (抽题规则：自定义 20) => 删除 - 列表单行数据(当前列数据，当前列下标，大题当前数据，大题当前下标)
    deleteListData20(row, rowIndex, clauseList, clauseListIndex) {
      console.log(row, rowIndex, clauseList, clauseListIndex)
      this.pageData.partitionList[clauseListIndex].pctzs = Number(this.pageData.partitionList[clauseListIndex].pctzs) - Number(row.showQuestionNum);
      this.pageData.partitionList[clauseListIndex].pctzfs = (Number(this.pageData.partitionList[clauseListIndex].pctzfs) - Number(row.zfs)).toFixed(2);
      clauseList.splice(rowIndex, 1);
      this.setExtractTotal();
    },
    // 预(抽题数)
    setExtractTotal() {
      this.pageData.extractTotal = 0;
      for (let i = 0; i < this.pageData.partitionList.length; i++) {
        // 抽题总数
        this.pageData.extractTotal = Number(this.pageData.extractTotal) + Number(this.pageData.partitionList[i].pctzs);
      }
    },
    // 取消
    noPreserve() {
      this.$router.back();
    },
    // 保存
    okPreserve() {
      this.$refs['courseTypeDialogFromRef'].validate((valid) => {
        if (valid) {
          // 检查是否有大题
          if (this.pageData.partitionList.length) {
            // 循环校验：每一大题都，不能为空的都不许为空
            for (let i = 0; i < this.pageData.partitionList.length; i++) {
              if (this.pageData.partitionList[i].partitionName && this.pageData.partitionList[i].clauseList.length) { // 大题标题名称 & 大题数量
                for (let n = 0; n < this.pageData.partitionList[i].clauseList.length; n++) {
                  // 抽题规则：自定义 10
                  if(this.pageData.extractQuestionType == '10'){
                    if (!this.pageData.partitionList[i].clauseList[n].questionType) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的试题类型不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                    if (this.pageData.isKnowledge && (typeof this.pageData.partitionList[i].clauseList[n].knowledgeId == 'string') && !this.pageData.partitionList[i].clauseList[n].knowledgeId) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的知识点不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                    if ((typeof this.pageData.partitionList[i].clauseList[n].showQuestionNum == 'string') && !this.pageData.partitionList[i].clauseList[n].showQuestionNum) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的学员可见不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                    if ((typeof this.pageData.partitionList[i].clauseList[n].hideQuestionNum == 'string') && !this.pageData.partitionList[i].clauseList[n].hideQuestionNum) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的学员不可见不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                    if ((typeof this.pageData.partitionList[i].clauseList[n].score == 'string') && !this.pageData.partitionList[i].clauseList[n].score) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的分数设置不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                  }
                  // 抽题规则：权重 20
                  if(this.pageData.extractQuestionType == '20'){
                    if (!this.pageData.partitionList[i].clauseList[n].questionType) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的试题类型不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                    if ((typeof this.pageData.partitionList[i].clauseList[n].showQuestionNum == 'string') && !this.pageData.partitionList[i].clauseList[n].showQuestionNum) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的抽提数量不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                    if ((typeof this.pageData.partitionList[i].clauseList[n].score == 'string') && !this.pageData.partitionList[i].clauseList[n].score) {
                      this.$message({
                        type: "warning",
                        message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则的每题分数不能为空',
                      });
                      this.onTouch('tacticsRule_right_list' + i);
                      return;
                    }
                  }
                  // 如果：学员可见提数 > 可见学员抽题总数
                  if (Number(this.pageData.partitionList[i].clauseList[n].showQuestionNum) > Number(this.pageData.partitionList[i].clauseList[n].showNum)) {
                    this.$message({
                      type: "warning",
                      message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则抽题数量大于抽题总数',
                    });
                    this.onTouch('tacticsRule_right_list' + i);
                    return;
                  }
                  // 如果：学员不可见提数 > 不可见学员抽题总数
                  if (Number(this.pageData.partitionList[i].clauseList[n].hideQuestionNum) > Number(this.pageData.partitionList[i].clauseList[n].hideNum)) {
                    this.$message({
                      type: "warning",
                      message: "第" + (i + 1) + '大题下，第' + (n + 1) + '行抽题规则抽题数量大于抽题总数',
                    });
                    this.onTouch('tacticsRule_right_list' + i);
                    return;
                  }
                }
              } else if (!this.pageData.partitionList[i].partitionName) { // !大题标题名称
                this.$message({
                  type: "warning",
                  message: "第" + (i + 1) + '大题标题不能为空',
                });
                this.onTouch('tacticsRule_right_list' + i);
                return;
              } else if (this.pageData.partitionList[i].clauseList.length < 1) { // !大题数量
                this.$message({
                  type: "warning",
                  message: "第" + (i + 1) + '大题没有添加抽题策略',
                });
                this.onTouch('tacticsRule_right_list' + i);
                return;
              } else {
                this.$message({
                  type: "error",
                  message: "数据有误",
                });
                this.onTouch('tacticsRule_right_list' + i);
                return;
              }
            }
          } else {
            this.$message({
              type: "error",
              message: "请先新增大题!",
            });
            return;
          }
          this.$post("/biz/exam/bank/extract/rule/insert", this.pageData, 3000, true, 6).then((res) => {
            this.$message({
              type: "success",
              message: "新增规则成功!",
            });
            this.$router.back();
          }).catch((err) => {
            console.log(err)
          });;
        }
      });
    },
    // 下一步
    okNext() {
      this.$refs['courseTypeDialogFromRef'].validate((valid) => {
        if (valid) {
          // 如果是自行组卷的复制
          if (this.tacticsPageData.ruleIdType == 'copyUpdate') {
            let params = {
              paperName: this.pageData.paperName,
              examType: this.pageData.examType,
              ruleId: this.tacticsPageData.ruleId,
            }
            this.$post("/biz/exam/bank/extract/rule/copyRule", params, 3000, true, 6).then((res) => {
              this.$message({
                type: "success",
                message: "复制成功!",
              });
              this.$router.push({
                path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
                query: {
                  paperId: res.data,
                  stu: 'edit',
                  isGroupPapers: true
                }
              });
              // this.$router.back();
            }).catch((err) => {
              console.log(err)
            });
          } else {
            this.$post("/biz/exam/bank/extract/rule/insertFixed", this.pageData, 3000, true, 6).then((res) => {
              this.$message({
                type: "success",
                message: "新增规则成功!",
              });
              this.$router.push({
                path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
                query: {
                  paperId: res.data,
                  stu: 'edit',
                  isGroupPapers: true
                }
              });
              // this.$router.back();
            }).catch((err) => {
              console.log(err)
            });
          }

        }
      })
      console.log('pageData', this.pageData)

    },
    // 数字 转 汉子数字
    numberToUpperCase(textIndex) {
      let newString = '';
      let newTextIndex = (textIndex + 1) + '';
      function sum(value, index) {
        var newValue = '';
        if ((textIndex === 9)) {
          return !index ? '十' : '';
        }
        let isSeat = (~~textIndex > 9 && ~~textIndex < 19);
        switch (~~value) {
          case 1:
            newValue = !index ? (isSeat ? '' : '一') : '十一';
            break;
          case 2:
            newValue = !index ? (isSeat ? '' : '二') : '十二';
            break;
          case 3:
            newValue = !index ? (isSeat ? '' : '三') : '十三';
            break;
          case 4:
            newValue = !index ? (isSeat ? '' : '四') : '十四';
            break;
          case 5:
            newValue = !index ? (isSeat ? '' : '五') : '十五';
            break;
          case 6:
            newValue = !index ? (isSeat ? '' : '六') : '十六';
            break;
          case 7:
            newValue = !index ? (isSeat ? '' : '七') : '十七';
            break;
          case 8:
            newValue = !index ? (isSeat ? '' : '八') : '十八';
            break;
          case 9:
            newValue = !index ? (isSeat ? '' : '九') : '十九';
            break;
          case 0:
            newValue = '十';
            break;
          default:
            break;
        }
        return newValue;
      }

      for (let i = 0; i < newTextIndex.length; i++) {
        newString += sum(newTextIndex.substring(i, i + 1), i);
      }
      return newString;
    },
    // 左侧大题锚点定位位置
    onTouch(id) {
      const sixteen = document.getElementById(id)
      sixteen.scrollIntoView({
        behavior: 'smooth' // 让滚动更丝滑
      }) // 你也可以设置丝滑滚动
    }
  },
};
</script>

<style lang="less" scoped>
.ovy-a {
  display: flex;
  position: relative;

  .tacticsRule_left {
    width: 150px;

    .shell {
      position: fixed;
      line-height: 30px;
      overflow-y: scroll;
      height: 81%;
      width: 150px;

      .shell_1 {
        font-weight: 600;
        font-size: 16px;
        padding-left: 20px;
      }

      .shell_2 {
        padding-left: 24px;

        &:hover {
          cursor: pointer;
          color: #409EFF;
        }
      }

      .shell_3 {
        padding-left: 4px;
      }

      .shell_3 {
        padding-left: 0px;
        color: #8a8a8a;
      }
    }
  }

  .tacticsRule_right {
    flex: 1;
    padding-left: 20px;
    width: 80%;

    // overflow-x: scroll;
    .el-form {
      width: 500px;

      .jbxx {
        /deep/ .el-form-item__label {
          font-size: 16px;
          font-weight: 600;
        }

      }
    }

    .tacticsRule_right_list {
      margin-bottom: 20px;

      .listTop {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        background: #f6f6f6;
        padding: 4px 0 4px 10px;

        .listTop_left_1 {
          font-size: 16px;
          font-weight: 600;
        }

        .listTop_left_2 {
          font-size: 18px;
          padding: 0 20px 0 10px;

          &:hover {
            cursor: pointer;
            color: #409EFF;
          }
        }

        .listTop_left_3 {
          color: #409EFF;
        }
      }

      .listBottom {
        padding-top: 10px;
        text-align: center;
      }
    }
  }

  .operateDiv {
    position: absolute;
    top: 0;
    right: 20px;
  }
}

/deep/ .el-table__fixed-right,
/deep/ .el-table .el-table__fixed {
  height: 100% !important;
}
</style>
